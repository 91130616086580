import React from 'react';
import {
	ContactContainer,
	ContactContentBlock,
	ContactMap,
	ContactMapContent,
	ContactMapAddress,
	Partnership,
	PartnershipHeadline,
	PartnershipContacts,
	PartnershipContactItem,
	PartnershipContactContacts,
} from './Contact.dump';
import { ReactComponent as TwitterRedIcon } from 'src/styles/assets/images/ui/icons/icon-twitter-red.svg';
import { ReactComponent as LinkedinRedIcon } from 'src/styles/assets/images/ui/icons/icon-linkedin-red.svg';

const Contact: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
	return (
		<>
			<ContactContainer {...props}>
				<div className="wrapper">
					<ContactContentBlock>{children}</ContactContentBlock>
				</div>
			</ContactContainer>
			<Partnership>
				<div className="wrapper">
					<PartnershipHeadline>Partnership</PartnershipHeadline>
					<PartnershipContacts>
						<PartnershipContactItem>
							<div className="name">Michael W. Wellman</div>
							<div className="position">CEO &amp; Founder</div>
							<PartnershipContactContacts>
								<li>
									<a href="https://www.linkedin.com/in/mwellman">
										<LinkedinRedIcon />
									</a>
								</li>
								<li>
									<a href="https://twitter.com/virgilsecurity">
										<TwitterRedIcon />
									</a>
								</li>
								<li>
									<a href="mailto:mwellman@virgilsecurity.com">mwellman@virgilsecurity.com</a>
								</li>
							</PartnershipContactContacts>
						</PartnershipContactItem>
						<PartnershipContactItem>
							<div className="name">Dmitry Dain</div>
							<div className="position">CTO &amp; co-Founder</div>
							<PartnershipContactContacts>
								<li>
									<a href="https://www.linkedin.com/in/dmitrydain">
										<LinkedinRedIcon />
									</a>
								</li>
								<li>
									<a href="https://twitter.com/dmitrydain">
										<TwitterRedIcon />
									</a>
								</li>
								<li>
									<a href="mailto:ddain@virgilsecurity.com">ddain@virgilsecurity.com</a>
								</li>
							</PartnershipContactContacts>
						</PartnershipContactItem>
					</PartnershipContacts>
				</div>
			</Partnership>
			<ContactMap>
				<div className="wrapper">
					<ContactMapContent>
						<ContactMapAddress>
							<p>
								9296 Sumner Lake Blvd,
								<br />
								Manassas, VA 20110, USA{' '}
							</p>
						</ContactMapAddress>
					</ContactMapContent>
				</div>
			</ContactMap>
		</>
	);
};

export default Contact;
