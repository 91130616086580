import styled from 'styled-components';
import { WIDTH_BREAKPOINT_LG, WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM } from 'src/constants';
import MapBg from '../../styles/assets/images/bg/contacts/contacts-map-bg.png';
import MapBg2x from '../../styles/assets/images/bg/contacts/contacts-map-bg@2x.png';

export const ContactContainer = styled.div`
	background: #f8fafd;
`;

export const ContactContentBlock = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 75px;
	position: relative;
	top: 45px;
	z-index: 1;
`;

export const ContactDescription = styled.p`
	line-height: 1.65;
`;

export const ContactCard = styled.div`
	background: #ffffff;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	margin-top: 85px;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		flex-direction: row;
	}
`;

export const ContactForm = styled.div`
	background: #ffffff;
	padding: 35px 20px 30px;

	@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
		padding: 35px 40px 30px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		flex: 0 1 840px;
		order: initial;
		width: 840px;
	}
`;

export const ContactFormHeadline = styled.h3`
	display: flex;
	font-family: 'Muller', sans-serif;
	font-size: 1.11rem;
	justify-content: space-between;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;
`;

export const FormFields = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'Muller', sans-serif;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;

	@media (min-width: ${WIDTH_BREAKPOINT_LG}) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

export const FormFieldsText = styled.div`
	display: flex;
	flex: 0 1 48%;
	flex-direction: column;
	margin-top: 35px;
	width: 100%;
`;

export const FormFieldsTextarea = styled(FormFieldsText)`
	flex: 0 1 100%;
`;

export const FormLabel = styled.label`
	cursor: pointer;
	font-size: 0.55rem;
	margin-bottom: 10px;
`;

export const FormInput = styled.input`
	border: 1px solid #ebebeb;
	font-family: 'Lato', sans-serif;
	font-size: 0.77rem;
	padding: 12px 15px;
`;
export const FormTextarea = styled.textarea`
	border: 1px solid #ebebeb;
	font-family: 'Lato', sans-serif;
	font-size: 0.77rem;
	height: 95px;
	padding: 12px 15px;
	resize: none;
`;

export const FormActions = styled.div`
	margin-top: 40px;
	text-align: left;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		text-align: right;
	}
`;

export const ContactInfo = styled.div`
	background: #bb241d;
	background: linear-gradient(to bottom, #da322c 0%, #bb241d 100%);
	color: #ffffff;
	display: flex;
	flex-direction: column;
	font-family: 'Muller', sans-serif;
	font-size: 0.66rem;
	letter-spacing: 1px;
	line-height: 1.5;
	padding: 35px 20px 30px;
	text-transform: uppercase;

	@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
		padding: 35px 40px 30px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		flex: 1 1 360px;
		width: 360px;
	}
`;

export const ContactInfoHeadline = styled.h3`
	font-size: 1.11rem;
`;

export const ContactInfoFields = styled.div`
	flex: 1;
	margin-top: 40px;
`;

export const ContactInfoField = styled.div`
	display: flex;
	margin-top: 45px;

	p + p {
		margin-top: 10px;
	}

	&:first-child {
		margin-top: 0;
	}
`;

export const ContactInfoIcon = styled.div`
	display: flex;
	justify-content: center;
	margin-right: 20px;
	min-width: 22px;
`;

export const ContactInfoLink = styled.a`
	align-self: center;
	color: white;
	text-transform: initial;
`;

export const ContactSocialList = styled.ul`
	align-items: center;
	display: flex;
	margin: 75px -20px 0;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		justify-content: space-between;
	}

	li {
		margin: 0 20px;
	}

	a {
		display: block;
		opacity: 0.25;

		&:hover {
			opacity: 1;
		}
	}
`;

export const Partnership = styled.div`
	padding: 140px 0 95px;
`;

export const PartnershipHeadline = styled.h2`
	font-family: 'Muller', sans-serif;
	font-size: 1.88rem;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;
`;

export const PartnershipContacts = styled.ul`
	display: flex;
	flex-direction: column;
	margin-top: 65px;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		flex-direction: row;
		justify-content: flex-start;
	}
`;

export const PartnershipContactItem = styled.li`
	display: flex;
	flex-direction: column;
	font-family: 'Muller', sans-serif;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;

	.name {
		flex: 1;
		font-size: 1.11rem;
	}

	.position {
		color: #a6a6a6;
		font-size: 0.77rem;
		margin-top: 10px;
	}

	&:last-child {
		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			margin-top: 40px;
		}

		@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
			margin-left: 100px;
		}
	}
`;

export const PartnershipContactContacts = styled.ul`
	align-items: center;
	display: flex;
	margin-top: 25px;

	li {
		font-size: 0.77rem;
		margin-right: 15px;
		text-transform: lowercase;

		@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
			margin-right: 25px;
		}

		&:last-child {
			border-left: 1px solid #ebebeb;
			padding-left: 15px;

			@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
				padding-left: 25px;
			}
		}
	}
`;

export const ContactMap = styled.div`
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		background-image: url(${MapBg2x});
	}
	background: url(${MapBg}) center top no-repeat;
	background-size: 1920px 700px;
	margin-bottom: -205px;
`;

export const ContactMapContent = styled.div`
	align-items: center;
	display: flex;
	height: 700px;
`;

export const ContactMapAddress = styled.div`
	background: #bb241d;
	background: linear-gradient(to right, #da322c 0%, #bb241d 100%);
	border-radius: 3px;
	box-shadow: 0 25px 20px -20px rgba(158, 54, 33, 0.5);
	color: #ffffff;
	line-height: 1.7;
	padding: 22px 32px;
`;
