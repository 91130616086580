import React, { useState } from 'react';
import Layout from 'src/layout/Layout';
import seoPage from '../../content/seo/contact.yml';
import { ReactComponent as MailIcon } from 'src/styles/assets/images/ui/icons/icon-mail-red.svg';
import { ReactComponent as GeoIcon } from 'src/styles/assets/images/ui/icons/icon-geo-white.svg';
import { ReactComponent as HelpIcon } from 'src/styles/assets/images/ui/icons/icon-help-white.svg';
import { ReactComponent as PhoneIcon } from 'src/styles/assets/images/ui/icons/icon-phone-white.svg';
import { ReactComponent as FacebookIcon } from 'src/styles/assets/images/ui/icons/icon-facebook.svg';
import { ReactComponent as GithubIcon } from 'src/styles/assets/images/ui/icons/icon-github.svg';
import { ReactComponent as TwitterIcon } from 'src/styles/assets/images/ui/icons/icon-twitter.svg';
import { ReactComponent as LinkedinIcon } from 'src/styles/assets/images/ui/icons/icon-linkedin.svg';
import { ReactComponent as MediumIcon } from 'src/styles/assets/images/ui/icons/icon-medium.svg';
import {
	ContactDescription,
	ContactCard,
	ContactInfo,
	ContactForm,
	ContactFormHeadline,
	FormFields,
	FormFieldsText,
	FormLabel,
	FormInput,
	FormTextarea,
	FormFieldsTextarea,
	FormActions,
	ContactInfoHeadline,
	ContactInfoFields,
	ContactInfoField,
	ContactInfoIcon,
	ContactInfoLink,
	ContactSocialList,
} from 'src/components/Contact/Contact.dump';
import Contact from 'src/components/Contact/Contact';
import { OldRedButton } from 'src/components/buttons/Button.dump';

const EMAIL = 'support@virgilsecurity.com';

const ContactPage: React.FC = () => {
	const [subject, setSubject] = useState('');
	const [body, setBody] = useState('');

	const onSubmit = () => {
		const endpoint = `mailto:${EMAIL}?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;
		window.location.href = endpoint;
	};

	return (
		<Layout seoPage={seoPage} theme="white">
			<Contact>
				<ContactDescription>
					We will be happy to help you with any questions. Call us, send an e-mail or use the
					feedback form.
				</ContactDescription>
				<ContactCard>
					<ContactForm>
						<ContactFormHeadline>
							Send us a message <MailIcon />
						</ContactFormHeadline>
						<FormFields>
							<FormFieldsText>
								<FormLabel htmlFor="subject">Topic</FormLabel>
								<FormInput
									type="text"
									id="subject"
									placeholder="Dear Brutus"
									value={subject}
									onChange={event => setSubject(event.target.value)}
								/>
							</FormFieldsText>
							<FormFieldsTextarea>
								<FormLabel htmlFor="body">Message</FormLabel>
								<FormTextarea
									id="body"
									placeholder="Hi there!"
									value={body}
									onChange={event => setBody(event.target.value)}
								/>
							</FormFieldsTextarea>
						</FormFields>
						<FormActions>
							<OldRedButton as="input" type="submit" onClick={onSubmit} value="Send" />
						</FormActions>
					</ContactForm>
					<ContactInfo>
						<ContactInfoHeadline>Contact information</ContactInfoHeadline>
						<ContactInfoFields>
							<ContactInfoField>
								<ContactInfoIcon>
									<GeoIcon />
								</ContactInfoIcon>
								<div>
									<p>Virgil Security, Inc.</p>
									<p>
										9296 Sumner Lake BLVD <br /> Manassas, Virginia 20110
									</p>
								</div>
							</ContactInfoField>
							<ContactInfoField>
								<ContactInfoIcon>
									<HelpIcon />
								</ContactInfoIcon>

								<ContactInfoLink target="_top" href="mailto:support@virgilsecurity.com">
									support@virgilsecurity.com
								</ContactInfoLink>
							</ContactInfoField>
							<ContactInfoField>
								<ContactInfoIcon>
									<PhoneIcon />
								</ContactInfoIcon>

								<ContactInfoLink href="tel:+1-571-348-4601">+1-571-348-4601</ContactInfoLink>
							</ContactInfoField>
						</ContactInfoFields>
						<ContactSocialList>
							<li>
								<a href="https://www.facebook.com/VirgilSec">
									<FacebookIcon />
								</a>
							</li>
							<li>
								<a href="https://github.com/VirgilSecurity">
									<GithubIcon />
								</a>
							</li>
							<li>
								<a href="https://twitter.com/virgilsecurity">
									<TwitterIcon />
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/virgil-security-inc-">
									<LinkedinIcon />
								</a>
							</li>
							<li>
								<a href="https://medium.com/@VirgilSecurity">
									<MediumIcon />
								</a>
							</li>
						</ContactSocialList>
					</ContactInfo>
				</ContactCard>
			</Contact>
		</Layout>
	);
};

export default ContactPage;
